import React from 'react';
import PropTypes from 'prop-types';
import { StyledText, StyledTextInLine } from './styled-component';

const Text = (props) => {
  const {
    children,
    style,
    color,
    variant,
    hoverUnderline,
    size,
    weight,
    isLink,
    onClick,
    ...rest
  } = props;
  if (variant === 'span') {
    return (
      <StyledTextInLine
        color={color}
        size={size}
        weight={weight}
        style={style}
        hoverUnderline={hoverUnderline}
        isLink={isLink}
        onClick={onClick}
        {...rest}
      >
        {children}
      </StyledTextInLine>
    );
  }
  return (
    <StyledText
      color={color}
      size={size}
      weight={weight}
      style={style}
      hoverUnderline={hoverUnderline}
      isLink={isLink}
      onClick={onClick}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  variant: PropTypes.oneOf(['p', 'span']),
  children: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.number,
  weight: PropTypes.number,
  style: PropTypes.any,
  hoverUnderline: PropTypes.bool,
  isLink: PropTypes.bool,
  onClick: PropTypes.func,
};

Text.defaultProps = {
  variant: 'p',
  children: '',
  style: null,
  color: '',
  hoverUnderline: false,
  isLink: false,
  onClick: () => {},
};

export default Text;
