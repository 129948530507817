import { GET } from '../../../services';

export const getOption = async (optKey) => {
  try {
    const response = await GET(`/options/${optKey}`);
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};
