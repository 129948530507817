import { isEmpty } from 'lodash';

export const getSaleCodeDisplay = ({ admins, label }) => {
  if (isEmpty(admins)) return label;

  const adminNames = admins.map((a) => a.name.replace(/\s+/g, ''));

  return `${label} | ${adminNames.join(', ')}`;
};

export const getSaleNames = (codes, options) => {
  if (isEmpty(codes) || isEmpty(options)) {
    return '-';
  }

  const result = codes.map((c) => {
    const sale = options.find((v) => v.id === c);
    return sale.label;
  });

  return result.join(', ');
};

export const mapSaleName = (code, options) => {
  const sale = options.find((v) => v.id === code);
  return sale.label || '-';
};
