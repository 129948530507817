import { useQuery } from '@tanstack/react-query';
import * as optionsApi from '../services';
import { getSaleCodeDisplay } from '../../telesales/logic';

export const useAdminsOptionQuery = () => {
  return useQuery({
    queryKey: ['adminOptions'],
    queryFn: () => optionsApi.getAdmins(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useSalesCodeOptionQuery = () => {
  return useQuery({
    queryKey: ['tlsCodeOptions'],
    queryFn: () => optionsApi.getSalesCode(),
    select: (data) => {
      const result = data
        .filter((item) => item?.value !== 'CS000')
        .filter(Boolean)
        .map((opt) => {
          return {
            id: opt?.value,
            label: getSaleCodeDisplay({ label: opt?.label, admins: opt?.admins }),
            value: opt?.value,
          };
        });
      return result;
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useCompanyNameOptionQuery = () => {
  return useQuery({
    queryKey: ['companyNameOptions'],
    queryFn: () => optionsApi.getCompanyName(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
export const useheadCountRangeOptionQuery = () => {
  return useQuery({
    queryKey: ['headCountRangeOptions'],
    queryFn: () => optionsApi.getHeadCountRange(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
