import { color } from '../color';

export const themeConfig = {
  sidebar: {
    dark: {
      background: '#141414',
    }, //defulat dark blue
    light: {
      background: color.grey900,
      color: 'white',
    },
  },
  content: {
    dark: {
      background: '#141414',
    }, //defulat dark blue
    light: {
      background: 'transparent',
    },
  },
  text: {
    dark: { color: 'white' },
    light: { color: 'black' },
  },
};
