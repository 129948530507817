import { GET, GET_WITH_PARAMS, POST } from '../../../services';

export const getAdminSales = async () => {
  try {
    const response = await GET('/options/admin');
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

export const getAdminSaleTable = async (filters) => {
  try {
    const response = await GET_WITH_PARAMS('/admin/get-admin-assign', filters);
    return response?.result;
  } catch (err) {
    throw new Error('error');
  }
};

export const updateAdminSale = async (body) => {
  try {
    const response = await POST('/admin/assign-code-user', body);
    return response;
  } catch (err) {
    throw new Error(err?.message || 'error');
  }
};
