import React from 'react';
import { FORM_ADMIN_SALES } from '../../constants/form/form';
import { COLUMN } from './constants/column';
import TabLayout from '../../components/tab-layout';
import ManageModal from '../../components/manage-modal';
import useAdminSaleState from '../../hooks/use-admin-sales-state';

function AdminSales() {
  const {
    options,
    tableProps,
    paginationProps,
    onSearch,
    onClearCustom,
    selectOtherProps,
    onSave,
  } = useAdminSaleState();

  const [modalProps, setModalProps] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const onToggleRow = (details) => {
    setModalProps({
      title: `จัดการสิทธิ์ของรหัสเซล ${details?.code}`,
      defaultValues: details?.adminId,
      id: details?.code,
    });

    setOpen(!open);
  };

  return (
    <>
      <TabLayout
        searchLayoutProps={{
          title: 'ตัวกรอง',
          isEnableEnterSubmit: true,
          formSearch: FORM_ADMIN_SALES({ options }),
          onSearch: onSearch,
          onClearCustom: onClearCustom,
        }}
        tableProps={{
          size: 'small',
          columns: COLUMN({ pageStep: paginationProps?.pageStep, onToggleRow }),
          scroll: { x: 'max-content' },
          style: { width: '100%' },
          ...tableProps,
        }}
        paginationProps={paginationProps}
      />
      <ManageModal
        open={open}
        onToggle={() => setOpen((prevOpen) => !prevOpen)}
        {...modalProps}
        onSave={onSave}
        selectProps={{
          selectValueKey: 'id',
          mappingData: (values) => ({
            id: values.id,
            name: values.label,
          }),
          placeholder: 'เลือกแอดมิน',
          ...selectOtherProps,
        }}
        renderRow={(item) => <span>{item.name}</span>}
      />
    </>
  );
}

export default AdminSales;
