import { useEffect, useState } from 'react';
import useWindowDimensions from '../../../utils/window/window-dimensions';

const MARGIN_TOP_BOTTOM = 40;
const HEADER_HEIGHT = 56;
const FOOTER_HEIGHT = 60;

export const useBodyHeight = () => {
  const { height } = useWindowDimensions();
  const [bodyHeight, setBodyHeight] = useState(null);

  const calcBodyHeight = () => {
    const totalHeight = height;
    const totalMargin = 2 * MARGIN_TOP_BOTTOM; // 2 * MARGIN_TOP_BOTTOM
    const totalHeaderFooterHeight = HEADER_HEIGHT + FOOTER_HEIGHT; // HEADER_HEIGHT + FOOTER_HEIGHT

    return totalHeight - (totalMargin + totalHeaderFooterHeight);
  };

  useEffect(() => {
    const handleResize = () => setBodyHeight(calcBodyHeight());

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [height]);

  const modalPreset = {
    bodyHeight,
    MARGIN_TOP_BOTTOM,
    HEADER_HEIGHT,
    FOOTER_HEIGHT,
  };

  return {
    modalPreset,
  };
};
