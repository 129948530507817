import React from 'react';
import { useSelector } from 'react-redux';
import { FORM_INPUT } from './forms/forms';
import { RenderForm } from '../../../../../../components/forms';

function ContactForm(props) {
  const { options, ...formProps } = props;
  const { user } = useSelector((state) => state.authen);

  return (
    <div>
      <RenderForm
        forms={FORM_INPUT({
          options,
          isDisabledKeySuccess: user?.role !== 'SUPER_ADMIN',
        })}
        {...formProps}
      />
    </div>
  );
}

export default ContactForm;
