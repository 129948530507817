import React from 'react';
import { CardContainerStyle, CardStyle } from './styled';

export const CardTheme = ({ type, title, content, style, ...props }) => {
  if (type === 'container') {
    return (
      <CardContainerStyle title={title || ''} style={style} {...props}>
        {content || props.children}
      </CardContainerStyle>
    );
  }

  return (
    <CardStyle title={title || ''} style={style} {...props}>
      {content || props.children}
    </CardStyle>
  );
};
