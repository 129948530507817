import { takeEvery } from 'redux-saga/effects';
import { login, loginGoogle, logout, getProfile, checkTokenLogin } from './api';
import * as type from './types';

function* authenSaga() {
  yield takeEvery(type.AUTHEN_LOGIN_REQ, login);
  yield takeEvery(type.AUTHEN_LOGOUT_REQ, logout);
  yield takeEvery(type.AUTHEN_LOGIN_GOOGLE_REQ, loginGoogle);
  yield takeEvery(type.AUTHEN_GET_PROFILE_REQ, getProfile);
  yield takeEvery(type.CHECK_TOKEN_AUTHEN_LOGIN_REQ, checkTokenLogin);
}

export default authenSaga;
