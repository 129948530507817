const { QueryClient } = require('@tanstack/react-query');

export const config = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      // ข้อมูลจะถือว่ายังใหม่ (fresh) อยู่ 5 นาที
      gcTime: 10 * 60 * 1000, // เก็บข้อมูลในแคชไว้ 10 นาที
      retry: 1, // ลองดึงข้อมูลใหม่ 1 ครั้งหากเกิดข้อผิดพลาด
      refetchOnWindowFocus: false, // ไม่ดึงข้อมูลใหม่เมื่อกลับมาที่หน้าต่าง
      refetchOnReconnect: true, // ดึงข้อมูลใหม่เมื่อเชื่อมต่ออินเทอร์เน็ตกลับมา
    },
  },
});
