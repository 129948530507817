import React from 'react';
import { ModalAntd, ModalContentContainer } from './styled-component';
import { ConfigProvider } from 'antd';
import { useBodyHeight } from './hooks/useBodyHeight';

export const ModalTheme = (props) => {
  const { open, title, onToggle, children, ...otherProps } = props;

  const { modalPreset } = useBodyHeight();

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            wireframe: true,
            motion: false,
          },
        },
      }}
    >
      <ModalAntd
        title={title || 'Basic Modal'}
        open={open}
        onOk={onToggle}
        onCancel={onToggle}
        maskClosable={false}
        destroyOnClose={true}
        footer={null}
        modalPreset={modalPreset}
        {...otherProps}
      >
        <ModalContentContainer>{children}</ModalContentContainer>
      </ModalAntd>
    </ConfigProvider>
  );
};
