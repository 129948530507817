import { Pagination as PaginationAntd, Space } from 'antd';
import React from 'react';

function Pagination(props) {
  const { children, ...paginationProps } = props;

  if (children) {
    return (
      <Space direction="vertical" style={{ display: 'flex' }}>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 8px', width: '100%' }}
        >
          <PaginationAntd {...paginationProps} />
        </div>
        {children}
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 8px', width: '100%' }}
        >
          <PaginationAntd {...paginationProps} />
        </div>
      </Space>
    );
  }

  return (
    <>
      <PaginationAntd {...paginationProps} />
    </>
  );
}

export default Pagination;
