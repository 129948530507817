import styled from 'styled-components';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  ${(props) => props.gap && `gap: ${props.gap};`};
  ${(props) => props.isFluid && `height: 100%;`};
`;
