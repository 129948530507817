import React from 'react';
import { FORM_COMPANY_SALES } from '../../constants/form/form';
import { COLUMN } from './constants/column';
import TabLayout from '../../components/tab-layout';
import ManageModal from '../../components/manage-modal';
import useCompanySalesState from '../../hooks/use-company-sales-state';
import { mapSaleName } from '../../../telesales/logic';

function CompanySales() {
  const { tableProps, selectProps, paginationProps, options, onSave, onSearch, onClearCustom } =
    useCompanySalesState();
  const [modalProps, setModalProps] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const onToggleRow = (details) => {
    setModalProps({
      title: `จัดการสิทธิ์ ${details?.name}`,
      defaultValues: details?.code ? details?.code?.map((v) => ({ id: v, value: v })) : [],
      id: details?.id,
    });

    setOpen(!open);
  };

  return (
    <>
      <TabLayout
        searchLayoutProps={{
          title: 'ตัวกรอง',
          isEnableEnterSubmit: true,
          formSearch: FORM_COMPANY_SALES({ options }),
          onSearch: onSearch,
          onClearCustom: onClearCustom,
        }}
        tableProps={{
          size: 'small',
          columns: COLUMN({
            pageStep: paginationProps?.pageStep,
            onToggleRow,
            saleCodeOptions: options?.saleCode,
          }),
          scroll: { x: 'max-content' },
          style: { width: '100%' },
          ...tableProps,
        }}
        paginationProps={paginationProps}
      />
      <ManageModal
        open={open}
        onToggle={() => setOpen((prevOpen) => !prevOpen)}
        {...modalProps}
        onSave={onSave}
        selectProps={{
          selectValueKey: 'id',
          placeholder: 'เลือกรหัสเซล',
          mappingData: (values) => ({
            id: values.id,
            value: values.value,
            label: values.label,
          }),
          ...selectProps,
        }}
        renderRow={(item) => <span>{mapSaleName(item.value, options?.saleCode)}</span>}
      />
    </>
  );
}

export default CompanySales;
