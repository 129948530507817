import React, { useState } from 'react';
import Row from '../../../../components/row';
import { Select, Space } from 'antd';
import { ModalTheme } from '../../../../components/modal';
import { ButtonTheme } from '../../../../components/buttons';
import { column } from './constants/column';
import { color } from '../../../../resources';
import TableCustom from '../../components/table-custom';
import {
  useHistoryCompanyListQuery,
  useHistoryContactListQuery,
} from '../../hooks/use-history-call-query';
import { generateContactOptions } from './logic';
import { useContactCompanyQuery } from '../../hooks/use-contact-query';
import { ScrollBox, HeaderBox, Container } from '../../styled-component';

function HistoryCallModal(props) {
  const { contactId, companyId } = props;
  const { data: fetchContacts, isLoading: isContactLoading } = useContactCompanyQuery(companyId);
  const options = generateContactOptions(fetchContacts, companyId);

  const [selectedOption, setSelectedOption] = useState(contactId || companyId || 'ทั้งหมด');

  const { data, isLoading } =
    companyId === selectedOption
      ? useHistoryCompanyListQuery(selectedOption)
      : useHistoryContactListQuery(selectedOption);

  const onClose = () => {
    props.onToggle({ defaultValues: {} });
  };

  return (
    <ModalTheme width={1000} title="ประวัติการโทร" footer={<Footer onClose={onClose} />} {...props}>
      <Container>
        <HeaderBox height={32}>
          <Select
            loading={isContactLoading}
            defaultValue={selectedOption}
            style={{ width: 120 }}
            options={options}
            onChange={(e) => setSelectedOption(e)}
          />
        </HeaderBox>

        <ScrollBox height={32}>
          <TableCustom
            loading={isLoading}
            scroll={{
              x: 'max-content',
            }}
            columns={column()}
            dataSource={data}
            hasPagination={false}
            sticky={true}
            isShowNo
          />
        </ScrollBox>
      </Container>
    </ModalTheme>
  );
}

function Footer({ onClose }) {
  return (
    <Row justifyContent="flex-end" alignItems="center">
      <ButtonTheme
        useFor="CUSTOM"
        title="ปิด"
        type="primary"
        color={color.blue80}
        onClick={onClose}
      />
    </Row>
  );
}

export default HistoryCallModal;
