import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text/index.jsx';
import { Checkbox } from 'antd';
import { renderTypeError } from '../index.jsx';

const CheckboxSingleComponent = ({ control, item, handleChange }) => {
  const { rules, name, defaultValue, label, ...checkboxProps } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Checkbox
        id={name}
        key={`${name}${value}`}
        checked={value}
        disabled={item.disabled || false}
        onClick={(e) => {
          handleChange(e, item);
        }}
        {...checkboxProps}
      >
        {label}
      </Checkbox>
      <br />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedCheckboxSingle = CheckboxSingleComponent;
