import { Space } from 'antd';
import React from 'react';
import { TextInputLabel } from '../../../../../../components/text';
import { TextWrapper } from './styled-component';

const LastNoteComponent = (props) => {
  const { text } = props;
  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <TextInputLabel text="โน้ตข้อความล่าสุด" />
      <TextWrapper>{text}</TextWrapper>
    </Space>
  );
};

export default LastNoteComponent;
