import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schema } from '../forms/yup';
import { openAlert } from '../../../../../components/alert/useAlert';

export const useFormState = ({ contactDetails = {}, onAction = () => {} }) => {
  const defaultValues = {
    name: contactDetails?.name || '',
    position: contactDetails?.position || '',
    phone: contactDetails?.phone || '',
    email: contactDetails?.email || '',
    isFavorite: contactDetails?.isFavorite || false,
  };

  const {
    control,
    formState: { isDirty },
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const formProps = {
    control: control,
    setValue: setValue,
    getValues: getValues,
  };

  const onCancel = ({ onAfterFinish }) => {
    if (isDirty) {
      openAlert({
        model: 'confirm',
        title: 'คุณต้องการออกจากการแก้ไข ?',
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          console.log('onOk');
          onAfterFinish();
        },
        onCancel: () => {},
      });
      return;
    }
    onAfterFinish();
  };

  const onSubmit = () => {
    handleSubmit(
      (formValues) => {
        onAction(formValues);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  return {
    defaultValues,
    formProps,
    onCancel,
    onSubmit,
  };
};
