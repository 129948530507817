const EMPLOYEE_SIZE = [
  { id: 'OPT_1', tagColor: 'default', label: '1 - 10 คน' },
  { id: 'OPT_2', tagColor: 'default', label: '11 - 50 คน' },
  { id: 'OPT_3', tagColor: 'gold', label: '51 - 200 คน' },
  { id: 'OPT_4', tagColor: 'gold', label: '201 - 500 คน' },
  { id: 'OPT_5', tagColor: 'green', label: '501 - 1,000 คน' },
  { id: 'OPT_6', tagColor: 'green', label: '1,001 - 5,000 คน' },
  { id: 'OPT_7', tagColor: 'blue', label: '5,001 - 10,000 คน' },
  { id: 'OPT_8', tagColor: 'blue', label: 'มากกว่า 10,001 คน' },
];

export const getColor = (id) => EMPLOYEE_SIZE.find((item) => item.id === id)?.tagColor || null;

export const getLabel = (id) => EMPLOYEE_SIZE.find((item) => item.id === id)?.label || '-';
