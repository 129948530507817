export const getkeySuccessColor = (text) => {
  switch (text) {
    case 'buy':
      return 'gold';
    case 'success':
      return 'green';
    case 'unsuccess':
      return 'red';
    case 'unknown':
      return 'geekblue';

    default:
      return 'default';
  }
};

export const getStageManualColor = (text) => {
  switch (text) {
    case 'success':
      return 'green';
    case 'unsuccess':
      return 'red';

    default:
      return 'default';
  }
};

export const getStageAutoColor = (text) => {
  switch (text) {
    case 'active':
      return 'green';
    case 'inactive':
      return 'red';

    default:
      return 'orange';
  }
};
