import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { Input as InputAntd, InputNumber as InputNumberAntD } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text/index.jsx';
import { renderTypeError } from '../index.jsx';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const InputLabel = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputType = 'text',
    inputStep = '0.01',
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd
        id={name}
        name={name}
        value={value}
        type={inputType}
        step={inputStep}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const InputArea = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputType = 'text',
    inputStep = '0.01',
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd.TextArea
        id={name}
        name={name}
        value={value}
        type={inputType}
        step={inputStep}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const InputNumber = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputPrecision = 2,
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputNumberAntD
        style={{ width: '100%', textAlign: 'right', ...item.style }}
        id={name}
        name={name}
        value={value}
        precision={inputPrecision}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedInputLabel = InputLabel; //memo(, compareRender);

const InputPassword = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    dataCy,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextXSMall text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd.Password
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...dataCy}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedInputPassword = InputPassword;
