import { useState } from 'react';
import { useAdminSaleTableQuery, useUpdateAdminSale } from './use-admin-sales-query';
import { useAdminsOptionQuery, useSalesCodeOptionQuery } from './use-options-query';
import { omitBy } from 'lodash';
import { openAlert } from '../../../components/alert/useAlert';

const useAdminSaleState = () => {
  const [filters, setFilters] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useAdminSaleTableQuery(filters);
  const updateAdmin = useUpdateAdminSale();

  const adminOptions = useAdminsOptionQuery();
  const saleCodeOptions = useSalesCodeOptionQuery();

  const onSave = async ({ id, updateValues }) => {
    try {
      const body = {
        code: id,
        userAdmin: updateValues
          .filter((u) => u.status === 'added' || !u.status)
          .map((u) => ({
            id: u?.id,
          })),
      };
      await updateAdmin.mutateAsync(body);
    } catch (e) {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    }
  };

  const onSearch = (newValues) => {
    const updatedFilter = {
      pageIndex: 0,
      pageSize: 10,
      ...newValues,
    };

    const filteredUpdatedFilter = omitBy(updatedFilter, (v) => v == null);
    setFilters(filteredUpdatedFilter);
  };

  const onClearCustom = () => {
    const updatedFilter = { pageIndex: 0, pageSize: 10 };
    setFilters(updatedFilter);
  };

  const onPageChange = (currentPage, currentPageSize) => {
    setFilters((prev) => ({
      ...prev,
      pageIndex: currentPage - 1,
      pageSize: currentPageSize,
    }));
  };

  const selectOtherProps = {
    loading: adminOptions.loading || false,
    options: (disabledValues) => {
      const formattedOpt = adminOptions?.data
        ? adminOptions?.data.map((opt) => ({ ...opt, id: opt.value }))
        : [];
      if (!disabledValues) return formattedOpt;
      return formattedOpt.filter((f) => !disabledValues.some((d) => d.id === f.id));
    },
  };

  const tableProps = {
    loading: table?.isLoading || false,
    dataSource: table?.data?.data || [],
  };

  const paginationProps = {
    current: filters.pageIndex + 1,
    pageSize: filters.pageSize,
    total: table?.data?.total || 0,
    pageStep: filters.pageIndex * filters.pageSize + 1,
    onChange: onPageChange,
  };

  return {
    options: {
      admin: adminOptions.data || [],
      saleCode: saleCodeOptions.data || [],
    },
    selectOtherProps,
    tableProps,
    paginationProps,
    onSave,
    onSearch,
    onClearCustom,
  };
};

export default useAdminSaleState;
