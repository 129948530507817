import React from 'react';
import { DateColumn } from '../../table/components/render-column';
import Text from '../../../../../components/text-new';
import * as packageUtils from '../../../../../utils/company/package';

export const column = () => {
  return [
    {
      title: 'Package / Item',
      dataIndex: 'packageName',
      key: 'packageName',
    },
    {
      title: 'วันที่ซื้อ',
      dataIndex: 'packageStart',
      key: 'packageStart',
      align: 'center',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'วันหมดอายุ',
      dataIndex: 'packageExp',
      key: 'packageExp',
      align: 'center',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'ระยะเวลา',
      dataIndex: 'packageDuration',
      key: 'packageDuration',
      align: 'center',
      render: (data) => <Text variant="span">{data || '-'}</Text>,
    },
    {
      title: 'สถานะ',
      dataIndex: 'packageStatus',
      key: 'packageStatus',
      align: 'center',
      render: (status) => packageUtils.renderPackageTag(status),
    },
  ];
};
