import React from 'react';
import { isEmpty } from 'lodash';
import Column from '../../../../../components/column';
import Text from '../../../../../components/text-new';
import CircleDot from '../../../../../components/dot';
import * as companyUtils from '../../../../../utils/company';
import * as clientUtils from '../../../../../utils/client';
import * as dateTimeUtils from '../../../../../utils/date-time';
import * as logic from '../logic';
import { getkeySuccessColor, getStageAutoColor, getStageManualColor } from './logic';
import { Badge, Popover, Tag } from 'antd';
import { color, Icon } from '../../../../../resources';
import * as calledStatusUtils from '../../../../../utils/company/call-status';
import { getSaleNames } from '../../../logic';
import { isArray } from 'lodash';

const COLUMN_STYLES = {
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
};

export function CompanyInformation({ data }) {
  return (
    <Column>
      <Text>
        วันที่สมัคร : {dateTimeUtils.fullThaiDateShortMonth(data?.createDate)} ({data?.caNumber})
      </Text>
      <Text>
        บริษัท :{' '}
        <a
          href={clientUtils.generateCompanyProfileUrl(data?.authorId)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black' }}
        >
          {data?.name}
        </a>
      </Text>
      <Text>
        สถานะบริษัท :{' '}
        <Text
          variant="span"
          style={{ marginLeft: '4px' }}
          color={companyUtils.getBadgeColor(data?.status)}
        >
          {companyUtils.renderVerifyBadgeName(data?.status)}
        </Text>{' '}
        | <Text variant="span">Logo {data?.isHaveLogo ? 'มี' : 'ไม่มี'}</Text> |{' '}
        <Text variant="span">Cover {data?.isHaveCover ? 'มี' : 'ไม่มี'}</Text>
      </Text>
    </Column>
  );
}

export function JoinColumn({ data, pattern = ', ' }) {
  if (!isArray(data)) {
    return <EmptyColumn />;
  }

  return <Text variant="span">{data.join(pattern)}</Text>;
}

export function DateColumn({ date }) {
  return <Text variant="span">{dateTimeUtils.fullThaiDateShortMonth(date)}</Text>;
}

export function DateFormatColumn({ date, format = 'YYYY/MM' }) {
  if (!date) return <EmptyColumn />;
  return (
    <Text variant="span">{dateTimeUtils.formatDateCustomWithInputFormat({ date, format })}</Text>
  );
}

export function TotalInformation({ total }) {
  return (
    <Column {...COLUMN_STYLES}>
      <Text variant="span">{total || 0}</Text>
    </Column>
  );
}

export function PackageInformation({ id, onAction }) {
  if (!id) return <EmptyColumn />;
  return (
    <Column {...COLUMN_STYLES}>
      <Text isLink variant="span" onClick={onAction}>
        ดูเพิ่มเติม
      </Text>
    </Column>
  );
}

export function FollowUpColumn({ data }) {
  if (!data) return <EmptyColumn />;

  return (
    <Column {...COLUMN_STYLES}>
      <Text variant="span">{dateTimeUtils.fullThaiDateShortMonth(data)}</Text>
      <Text variant="span">
        เวลา {dateTimeUtils.formatDateCustom({ date: data, format: 'MM:ss' })} น.
      </Text>
    </Column>
  );
}

export function CalledStatusColumn({ data }) {
  if (!data || isEmpty(data)) {
    return <Text variant="span">-</Text>;
  }
  return <Badge color={calledStatusUtils.getColor(data)} text={calledStatusUtils.getLabel(data)} />;
}

export function LastCallColumn({ data }) {
  if (!data?.lastedDate && !data?.total) return <EmptyColumn />;

  return (
    <Column {...COLUMN_STYLES}>
      <Text variant="span">{dateTimeUtils.fullThaiDateShortMonth(data?.lastedDate)}</Text>
      {data?.total && <Text variant="span">{data?.total || 0} ครั้ง</Text>}
    </Column>
  );
}

export function MappingColumn({ data, options }) {
  if (!data || !options) return <EmptyColumn />;

  const obj = options.find((opt) => opt?.value === data);

  return <Text variant="span">{obj?.label}</Text>;
}

export function StageAutoColumn({ data }) {
  if (!data) return <EmptyColumn />;

  return (
    <Tag bordered color={getStageAutoColor(data)} style={{ textTransform: 'capitalize' }}>
      {data}
    </Tag>
  );
}

export function KeySuccessColumn({ data, options }) {
  if (!data || !options) return <EmptyColumn />;

  const obj = options.find((opt) => opt?.value === data);

  return (
    <Tag bordered color={getkeySuccessColor(obj?.value)}>
      {obj?.label}
    </Tag>
  );
}

export function StageManualColumn({ data, options }) {
  if (!data || !options) return <EmptyColumn />;

  const obj = options.find((opt) => opt?.value === data);

  return (
    <Tag bordered color={getStageManualColor(obj?.parentValue)}>
      {obj?.label}
    </Tag>
  );
}

export function PurchaseOccasionColumn({ data, options }) {
  if (!data || !options) return <EmptyColumn />;

  const obj = options.find((opt) => opt?.value === data);

  return (
    <CircleDot isMiddle color={logic.getPurchaseOccasionStatus(obj?.value)}>
      <Text variant="span">{obj?.label}</Text>
    </CircleDot>
  );
}

export function PurchaseOccasionOptions({ data }) {
  if (isEmpty(data)) return <EmptyColumn />;

  return (
    <CircleDot isMiddle color={logic.getPurchaseOccasionStatus(data?.value)}>
      <Text variant="span">{data?.label}</Text>
    </CircleDot>
  );
}

export function NoteColumn({ data, onAction }) {
  if (!data) return <EmptyColumn />;

  return (
    <Column {...COLUMN_STYLES}>
      <Popover
        placement="bottom"
        content={
          <div style={{ maxWidth: '400px', maxHeight: '300px', overflow: 'auto' }}>
            <Text variant="span" style={{ wordBreak: 'break-all' }}>
              {data}
            </Text>
          </div>
        }
      >
        <Text isLink variant="span" onClick={onAction}>
          ดูโน้ต
        </Text>
      </Popover>
    </Column>
  );
}

export function QuotationColumn({ data, onAction }) {
  if (isEmpty(data)) return <EmptyColumn />;

  return (
    <Column {...COLUMN_STYLES}>
      <Text isLink variant="span" onClick={onAction}>
        ดูใบเสนอราคา
      </Text>
    </Column>
  );
}

export function CheckedColumn({ value }) {
  return (
    <Text variant="span" color={value ? color.green80 : color.redStatus}>
      {value ? <Icon.checkCircle size={16} /> : <Icon.crossCloseCircle size={16} />}
    </Text>
  );
}

export function EmptyColumn({ data }) {
  if (isEmpty(data)) {
    return <Text variant="span">-</Text>;
  }

  return <Text variant="span">{data}</Text>;
}

export function SaleNamesColumn({ values, options }) {
  if (isEmpty(values)) return <EmptyColumn />;
  const displayText = getSaleNames(values, options);

  return <span>{displayText}</span>;
}
