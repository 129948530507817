import React from 'react';
import { Tag } from 'antd';
import TableActions from '../../../components/table-actions';
import * as logic from '../../../../telesales/logic';
import * as headCountUtils from '../../../../../utils/company/employee-size';

export const COLUMN = ({ pageStep, onToggleRow, saleCodeOptions }) => {
  return [
    {
      title: 'No.',
      width: 70,
      align: 'center',
      render: (_, records, index) => <span>{index + pageStep}</span>,
    },

    {
      title: 'ชื่อบริษัท',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'CA',
      dataIndex: 'caNumber',
      key: 'caNumber',
      align: 'center',
      render: (value) => <span>{value || '-'}</span>,
    },
    {
      title: 'รหัสเซล',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 250,
      render: (values) => <span>{logic.getSaleNames(values, saleCodeOptions)}</span>,
    },
    {
      title: 'จำนวนพนักงาน',
      dataIndex: 'headcountRangeValue',
      key: 'headcountRangeValue',
      align: 'center',
      render: (value) => {
        if (!value) {
          return <span>-</span>;
        }
        return <Tag color={headCountUtils.getColor(value)}>{headCountUtils.getLabel(value)}</Tag>;
      },
    },
    {
      title: '',
      key: 'actions',
      width: 150,
      align: 'center',
      render: (_, records) => <TableActions onClick={() => onToggleRow(records)} />,
    },
  ];
};
