import React from 'react';
import { ButtonIconTheme } from '../../../../../components/buttons';
import { color, Icon } from '../../../../../resources';
import {
  CalledStatusColumn,
  CheckedColumn,
  DateColumn,
  DateFormatColumn,
  FollowUpColumn,
  JoinColumn,
  KeySuccessColumn,
  LastCallColumn,
  NoteColumn,
  PackageInformation,
  PurchaseOccasionColumn,
  QuotationColumn,
  SaleNamesColumn,
  StageAutoColumn,
  StageManualColumn,
  TotalInformation,
} from '../components/render-column';
import Text from '../../../../../components/text-new';
import { fullThaiDateShortMonth } from '../../../../../utils/date-time';
import { getBadgeColor, renderVerifyBadgeName } from '../../../../../utils/company';
import { generateCompanyProfileUrl } from '../../../../../utils/client';
import LinkComponent from '../../../../../components/link';
import Row from '../../../../../components/row';

export const column = ({
  onToggleContact,
  onToggleHistoryCall,
  onToggleEditCallInfo,
  onTogglePackageModal,
  onToggleQuotationModal,
  options,
  salesCodeOption,
  pageStep,
}) => {
  return [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => <Text variant="span">{index + pageStep}</Text>,
    },
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      width: '250px',
      fixed: 'left',
      render: (data) => (
        <Text variant="span">
          <LinkComponent isNewTab href={generateCompanyProfileUrl(data?.authorId)}>
            <span>{data?.name}</span>
          </LinkComponent>
        </Text>
      ),
    },
    {
      title: 'CA',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      render: (data) => <Text variant="span">{data?.caNumber}</Text>,
    },
    {
      title: 'สถานะบริษัท',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      render: (data) => (
        <Text variant="span" style={{ marginLeft: '4px' }} color={getBadgeColor(data?.status)}>
          {renderVerifyBadgeName(data?.status)}
        </Text>
      ),
    },
    {
      title: 'วันที่สมัคร',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      render: (data) => <Text variant="span">{fullThaiDateShortMonth(data?.createDate)}</Text>,
    },
    {
      title: 'Stage Auto',
      dataIndex: 'stage',
      key: 'stage',
      align: 'center',
      render: (data) => <StageAutoColumn data={data} />,
    },
    {
      title: 'Key Success',
      dataIndex: 'keySuccess',
      key: 'keySuccess',
      align: 'center',
      render: (data) => <KeySuccessColumn data={data} options={options?.keySuccess?.data} />,
    },
    {
      title: 'Stage Manual',
      dataIndex: 'stageManual',
      key: 'stageManual',
      align: 'center',
      render: (data) => <StageManualColumn data={data} options={options?.stageManual?.data} />,
    },
    {
      title: 'โอกาสในการซื้อ',
      dataIndex: 'purchaseOccasion',
      key: 'purchaseOccasion',
      align: 'center',
      render: (data) => <PurchaseOccasionColumn data={data} options={options?.purchaseOccasion?.data} />,
    },
    {
      title: 'Logo',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      render: (data) => <CheckedColumn value={data.isHaveLogo} />,
    },
    {
      title: 'Cover',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      render: (data) => <CheckedColumn value={data.isHaveCover} />,
    },
    {
      title: 'เข้าใช้งานล่าสุด',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      align: 'center',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'businessType',
      key: 'businessType',
      align: 'center',
    },
    {
      title: 'จำนวนพนักงาน',
      dataIndex: 'headcountRange',
      key: 'headcountRange',
      align: 'center',
    },
    {
      title: 'ประกาศงานที่เปิด',
      dataIndex: 'totalJobPost',
      key: 'totalJobPost',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="ประกาศ" />,
    },
    {
      title: 'เครดิตซื้อที่ใช้',
      dataIndex: 'totalCreditUse',
      key: 'totalCreditUse',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="เครดิต" />,
    },
    {
      title: 'เครดิตฟรีที่ใช้',
      dataIndex: 'totalCreditFree',
      key: 'totalCreditFree',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="เครดิต" />,
    },
    {
      title: 'ยอดผู้สมัคร',
      dataIndex: 'candidate',
      key: 'candidate',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="คน" />,
    },
    {
      title: 'ยอดซื้อ',
      dataIndex: 'totalPurchase',
      key: 'totalPurchase',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="บาท" />,
    },
    {
      title: 'Package / Item',
      dataIndex: 'allPackage',
      key: 'allPackage',
      align: 'center',
      render: (_, records) => <PackageInformation id={records?._id} onAction={() => onTogglePackageModal(records)} />,
    },
    {
      title: 'Follow up date',
      dataIndex: 'followUpDate',
      key: 'followUp',
      align: 'center',
      render: (data) => <FollowUpColumn data={data} />,
    },
    {
      title: 'สถานะการติดต่อล่าสุด',
      dataIndex: 'calledStatus',
      key: 'calledStatus',
      align: 'center',
      render: (data) => <CalledStatusColumn data={data} />,
    },
    {
      title: 'คนที่แก้ไขล่าสุด',
      dataIndex: 'salesName',
      key: 'salesName',
      align: 'center',
    },
    {
      title: 'ผู้ดูแล',
      dataIndex: 'saleCodes',
      key: 'saleCodes',
      align: 'center',
      width: '200px',
      render: (data) => <SaleNamesColumn values={data} options={salesCodeOption?.data} />,
    },
    {
      title: 'โทรล่าสุด',
      dataIndex: 'lastCall',
      key: 'lastCall',
      align: 'center',
      render: (data) => <LastCallColumn data={data} />,
    },
    {
      title: 'โน้ตล่าสุด',
      dataIndex: 'lastNote',
      key: 'lastNote',
      align: 'center',
      render: (data) => <NoteColumn data={data} />,
    },
    {
      title: 'โน้ตข้อความเพิ่มเติม',
      dataIndex: 'additionalNote',
      key: 'additionalNote',
      align: 'center',
      render: (data) => <NoteColumn data={data} />,
    },

    {
      title: 'ใบเสนอราคา',
      dataIndex: 'quotations',
      key: 'quotations',
      align: 'center',
      width: '200px',
      render: (data, records) => (
        <QuotationColumn data={data} onAction={() => onToggleQuotationModal(records, data)} />
      ),
    },
    {
      title: 'ช่องทางที่รู้จัก JFF',
      dataIndex: 'knowUs',
      key: 'knowUs',
      align: 'center',
      width: '300px',
      render: (data) => <JoinColumn data={data} />,
    },
    {
      title: 'คนที่ได้จาก JFF/คน',
      dataIndex: 'totalCandidate',
      key: 'totalCandidate',
      align: 'center',
    },
    {
      title: 'ตำแหน่งที่ได้จาก JFF',
      dataIndex: 'positionReceived',
      key: 'positionReceived',
      align: 'center',
    },
    {
      title: 'ประกาศที่ใช้เฉลี่ยต่อเดือน',
      dataIndex: 'avgJobPost',
      key: 'avgJobPost',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="ประกาศ/เดือน" />,
    },
    {
      title: 'เครดิตค้นหาที่ใช้เฉลี่ยต่อเดือน',
      dataIndex: 'avgSearch',
      key: 'avgSearch',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="เครดิต/เดือน" />,
    },
    {
      title: 'ตำแหน่งที่หา(ประจำ)',
      dataIndex: 'favPosition',
      key: 'favPosition',
      align: 'center',
    },
    {
      title: 'สายงานที่หา',
      dataIndex: 'findPosition',
      key: 'findPosition',
      align: 'center',
      width: '300px',
      render: (data) => <JoinColumn data={data} />,
    },
    {
      title: 'เว็บหางานอื่นๆที่ใช้',
      dataIndex: 'competitors',
      key: 'competitors',
      align: 'center',
      width: '300px',
      render: (data) => <JoinColumn data={data} />,
    },
    {
      title: 'รอบอนุมัติงบประมาณ',
      dataIndex: 'budgetPeriod',
      key: 'budgetPeriod',
      align: 'center',
      render: (data) => <DateFormatColumn date={data} />,
    },
    {
      title: 'งบประมาณ',
      dataIndex: 'budget',
      key: 'budget',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="บาท/รอบ" />,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '160px',
      render: (_, records) => (
        <Row justifyContent="center" alignItems="center" gap="6px">
          <ButtonIconTheme
            useFor="CUSTOM"
            text="ผู้ติดต่อ"
            type="primary"
            color="#0D47A1"
            bgcolor="#0D47A1"
            icon={<Icon.userOutline color="white" />}
            block
            onClick={() => onToggleContact(records)}
          />
          <ButtonIconTheme
            useFor="CUSTOM"
            type="primary"
            text="แก้ไขข้อมูล"
            color="#FF7043 "
            bgcolor="#FF7043 "
            icon={<Icon.editOutline color="white" />}
            onClick={() => onToggleEditCallInfo(records)}
            block
          />
          <ButtonIconTheme
            useFor="CUSTOM"
            text="ประวัติการโทร"
            type="primary"
            color={color.grey900}
            bgcolor="#EEEEEE"
            icon={<Icon.callHistory color={color.grey900} />}
            onClick={() => onToggleHistoryCall(records?._id)}
            block
          />
        </Row>
      ),
    },
  ];
};
