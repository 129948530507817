import React, { useEffect } from 'react';
import { getProfile, login } from '../redux/authen/action';
import { useDispatch, useSelector } from 'react-redux';
import { getCookies } from '../store/useCookies';
import { Modal } from 'antd';
import { env } from '../utils/env/config';
import { openLoading } from '../components/alert/useAlert';
import { isEmpty } from 'lodash';

export const useAuthEffect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //
  }, [dispatch]);
};

export const useAuthLogin = () => {
  const { user } = useSelector((state) => state.authen);
  const dispatch = useDispatch();

  function toLogin() {
    return Modal.warning({
      title: 'Token is invalid',
      content: (
        <div>
          <p>กรุณาเข้าสู่ระบบใหม่อีกครั้ง</p>
        </div>
      ),
      okText: 'ไปที่หน้า Login',
      onOk: () => {
        openLoading();
        window.location.href = env.admin_web;
      },
    });
  }

  const onLogin = async (values) => {
    dispatch(login(values));
  };

  function checkLoginToken() {
    if (!getCookies('accessToken')) {
      toLogin();
      return;
    }

    if (isEmpty(user)) dispatch(getProfile());
  }

  function redirectToMain() {
    if (!getCookies('accessToken')) {
      toLogin();
    }
  }

  return {
    toLogin,
    checkLoginToken,
    redirectToMain,
    onLogin,
  };
};
