import { Badge, Row, Select, Space } from 'antd';
import React from 'react';
import Text from '../../../../../../components/text-new';
import { ButtonTheme } from '../../../../../../components/buttons';
import { color } from '../../../../../../resources';
import { formatSecondsToTime } from '../../../../../../utils/date-time';
import { CALL_STATUS } from '../../../../hooks/use-call-action';
import * as callStatusUtils from '../../../../../../utils/company/call-status';
import { Required } from '../../../../../../styles/global-style';
import { TextInputLabel, TextXSMall } from '../../../../../../components/text';
import { renderTypeError } from '../../../../../../components/forms';

function CallTimer(props) {
  const { callStatusProps } = props;

  const { options, error, ...selectProps } = callStatusProps;

  return (
    <div>
      <Space style={{ width: '100%' }} direction="vertical" align="center">
        <Text variant="span" color={color.grey100} size={20}>
          จับเวลาการโทรติดต่อ
        </Text>
        <Text variant="span" color={!props.isRunning ? color.grey400 : color.redStatus} size={40}>
          {formatSecondsToTime(props.time)}
        </Text>

        {props.status !== CALL_STATUS.CALLING ? (
          <ButtonTheme
            useFor="CUSTOM"
            title="เริ่มการโทร"
            type="primary"
            color={color.green80}
            onClick={props.onTriggerStartCall}
            loading={props?.status === CALL_STATUS.PENDING}
            disabled={
              props?.status === CALL_STATUS.WAITING_CONFIRM || props?.status === CALL_STATUS.PENDING
            }
          />
        ) : (
          <ButtonTheme
            useFor="CUSTOM"
            title="สิ้นสุดการโทร"
            type="primary"
            color={color.redStatus}
            loading={props?.status === CALL_STATUS.PENDING}
            onClick={props.onTriggerEndCall}
          />
        )}

        <Space direction="vertical" size={0}>
          <Row>
            <TextInputLabel text={'สถานะการติดต่อ'} />
            <Required>*</Required>
          </Row>
          <Select style={{ width: '200px' }} {...selectProps}>
            {options?.map((x) => {
              return (
                <Select.Option key={x.value} value={x.value}>
                  <Badge color={callStatusUtils.getColor(x?.value)} text={x?.label} />
                </Select.Option>
              );
            })}
          </Select>
          {error && <TextXSMall text={renderTypeError('required', error)} color="red" />}
        </Space>
      </Space>
    </div>
  );
}

export default CallTimer;
