import React from 'react';
import notFoundImg from '../../assets/images/404.png';
import { ButtonTheme } from '../../components/buttons';
import { navigateTo } from '../../utils/navigation';
import { ROUTES_PATH } from '../../resources/routes-name';
import { Image, Wrapper } from './styled-component';

const NotfoundScene = () => {
  return (
    <Wrapper>
      <Image src={notFoundImg} />
      <ButtonTheme
        useFor="custom"
        title="กลับไปหน้าหลัก"
        onClick={() => navigateTo({ pathname: ROUTES_PATH.ROUTE_MAIN.PATH })}
      />
    </Wrapper>
  );
};

export default NotfoundScene;
