import React from 'react';
import { CardTheme } from '../../../../components/card';
import Column from '../../../../components/column';
import { PageLayout } from '../../../../components/page-layout/PageLayout';
import TableComponent from '../../../../components/table';

function TabLayout(props) {
  const { searchLayoutProps, tableProps, paginationProps } = props;
  return (
    <CardTheme style={{ margin: '-16px 0 0 0', borderRadius: '0 0 8px 8px' }}>
      <Column isfluid justifyContent="center" alignItems="center" gap="32px">
        <PageLayout
          searchLayout={searchLayoutProps}
          cardProps={{
            style: {
              width: '100%',
              margin: 'unset',
            },
          }}
        />

        <TableComponent
          {...tableProps}
          pagination={{ position: ['topRight', 'bottomRight'], ...paginationProps }}
        />
      </Column>
    </CardTheme>
  );
}

export default TabLayout;
