import { DELETE, GET, POST, PUT } from '../../../services';

export const getContactCompany = async (companyId) => {
  try {
    const response = await GET(`/company-contact/${companyId}`);
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

export const editContact = async (contactId, reqBody) => {
  try {
    const response = await PUT(`/company-contact/update-contact/${contactId}`, reqBody);
    return response.result;
  } catch (err) {
    throw new Error('error');
  }
};

export const addContact = async (reqBody) => {
  try {
    const response = await POST(`/company-contact/create-company-contact`, reqBody);
    return response.result;
  } catch (err) {
    throw new Error('error');
  }
};

export const deleteContact = async (contactId) => {
  try {
    const response = await DELETE(`/company-contact/delete-contact/${contactId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};
