import React from 'react';
import { Layout, ConfigProvider } from 'antd';
import { getTheme, useTheme } from '../../resources/theme';
import SiderLayout from './layout/siderLayout';
import ContentLayout from './layout/contentLayout';
import { SIDEBAR_WIDTH } from '../../resources/constants/component-size';

const LayoutPage = () => {
  const { sidebarMode, globalTheme } = useTheme();

  return (
    <ConfigProvider theme={globalTheme}>
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <SiderLayout collapsed={false} getTheme={getTheme} sidebarMode={sidebarMode} />
        <Layout
          style={{
            marginLeft: SIDEBAR_WIDTH,
          }}
        >
          <ContentLayout getTheme={getTheme} sidebarMode={sidebarMode} />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutPage;
