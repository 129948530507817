import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text/index.jsx';
import { useMemo } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { renderTypeError } from '../index.jsx';

const CheckboxComponent = ({ control, item, handleChange }) => {
  const { rules, name, defaultValue, label, properties, dataCy, ...propsInput } = item;

  const { options } = properties;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const opt = useMemo(() => {
    return options.map((el) => (
      <Checkbox key={`${name}${el.value}`} value={el.value}>
        {el.label}
      </Checkbox>
    ));
  }, [options]);

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Checkbox.Group
        id={name}
        disabled={item.disabled || false}
        onChange={(e) => handleChange(e, item)}
        value={value}
        {...dataCy}
        {...propsInput}
      >
        <Row>
          <Col span={24}>{opt}</Col>
        </Row>
      </Checkbox.Group>
      <br />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedCheckbox = CheckboxComponent;
