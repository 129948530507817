import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DotElement, DotWrapper } from './styled-component';

const CircleDot = ({ size, color, margin, isMiddle, children }) => {
  if (isMiddle) {
    return (
      <DotWrapper>
        <DotElement size={size} color={color} margin={margin} />
        {children}
      </DotWrapper>
    );
  }

  return (
    <Fragment>
      <DotElement size={size} color={color} margin={margin} />
    </Fragment>
  );
};

CircleDot.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  margin: PropTypes.number,
  isMiddle: PropTypes.bool,
};

CircleDot.defaultProps = {
  size: 10,
  margin: 4,
  color: 'black',
  isMiddle: false,
};

export default CircleDot;
