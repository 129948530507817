export const generateContactOptions = (contacts, companyId) => {
  if (!contacts) return [];

  const options = contacts.map((c) => ({
    label: c?.name || '-',
    value: c?._id,
  }));

  return [
    {
      label: 'ทั้งหมด',
      value: companyId,
    },
    ...options,
  ];
};
