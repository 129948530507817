import React from 'react';
import TableComponent from '../../../../components/table';
import { EmptyColumn } from '../../views/table/components/render-column';
import Text from '../../../../components/text-new';

function TableCustom(props) {
  const { isShowStripe, isShowNo, ...propsTable } = props;

  const stripeProps = isShowStripe ? {} : { rowClassName: null };

  const columnRender = (column) => {
    const newColumn = column.map((item, index) => ({
      render: item?.render ? item?.render : (data) => <EmptyColumn data={data} />,
      ...item,
      title: item?.title && <span style={{ whiteSpace: 'nowrap' }}>{item.title}</span>,
      align: index === 0 && !item?.align ? 'left' : 'center',
    }));

    if (isShowNo) {
      return [
        {
          title: 'No.',
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          width: '90px',
          render: (text, record, index) => <Text variant="span">{index + 1}</Text>,
        },
        ...newColumn,
      ];
    }

    return newColumn;
  };

  const customProps = {
    ...propsTable,
    bordered: true,
    columns: columnRender(propsTable?.columns || []),
  };

  return <TableComponent {...stripeProps} {...customProps} />;
}

export default TableCustom;
