import React from 'react';
import Text from '../../../../../components/text-new';
import * as dateTimeUtils from '../../../../../utils/date-time';

export function DateColumn({ data }) {
  const date = dateTimeUtils.formatDate(data);
  return <Text variant="span">{date ? date : '-'}</Text>;
}

export function TimeColumn({ data }) {
  const time = dateTimeUtils.formatDateCustom({ date: data, format: 'HH:mm' });
  return <Text variant="span">{time ? `${time} น.` : '-'}</Text>;
}

export function DurationColumn({ duration }) {
  const time = dateTimeUtils.formatSecondsToTime(duration / 1000, 'mm:ss');

  return <Text variant="span">{time ? `${time} นาที` : '-'} </Text>;
}
