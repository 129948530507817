import React from 'react';
import { DateColumn, DurationColumn, TimeColumn } from '../components/render-column';

export const column = () => {
  return [
    {
      title: 'วันที่โทร',
      dataIndex: 'callDate',
      key: 'callDate',
      align: 'center',
      render: (data) => <DateColumn data={data} />,
    },
    {
      title: 'เวลาที่โทร',
      dataIndex: 'callTime',
      key: 'callTime',
      width: 100,
      render: (data) => <TimeColumn data={data} />,
    },
    {
      title: 'เวลาที่ใช้โทร',
      dataIndex: 'duration',
      key: 'duration',
      width: 120,
      render: (data) => <DurationColumn duration={data} />,
    },
    {
      title: 'โน้ต',
      dataIndex: 'note',
      key: 'note',
      width: '150px',
    },
    {
      title: 'ชื่อผู้ติดต่อ',
      dataIndex: 'customerName',
      key: 'customerName',
      width: 150,
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'customerPosition',
      key: 'customerPosition',
      width: 120,
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      width: 150,
    },
    {
      title: 'ผู้โทรติดต่อ',
      dataIndex: 'adminName',
      key: 'adminName',
      minWidth: 100,
    },
  ];
};
