import { useForm } from 'react-hook-form';
import { schema } from '../forms/yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const useFormState = ({ options, companyDetails }) => {
  const defaultValues = {
    note: '',
    keySuccess: '',
    stageManual: '',
    followUpDate: '',
    keySuccess: companyDetails?.keySuccess || '',
    stageManual: companyDetails?.stageManual || '',
    purchaseOccasion: companyDetails?.purchaseOccasion,
    knowUs: companyDetails?.knowUs,
    totalCandidate: companyDetails?.totalCandidate,
    positionReceived: companyDetails?.positionReceived,
    avgJobPost: companyDetails?.avgJobPost,
    avgSearch: companyDetails?.avgSearch,
    favPosition: companyDetails?.favPosition,
    findPosition: companyDetails?.findPosition || [],
    competitors: companyDetails?.competitors,
    budgetPeriod: companyDetails?.budgetPeriod,
    budget: companyDetails?.budget,
    quotations: companyDetails?.quotations.map((items) => items?.quotationNo) || [],
    additionalNote: companyDetails?.additionalNote,
    calledStatus: '',
  };

  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const formProps = {
    control: control,
    setValue: setValue,
    getValues: getValues,
    options: options || {},
  };

  const onChangeCalledStatus = (value) => {
    setValue('calledStatus', value);
  };

  const onSubmit = ({ onAction = () => {} }) => {
    handleSubmit(
      (formValues) => {
        onAction(formValues);
      },
      () => {},
    )();
  };

  const callStatusProps = {
    options: options?.callStatus?.data || [],
    loading: options?.callStatus?.isLoading || false,
    onChange: onChangeCalledStatus,
    error: errors?.calledStatus || '',
  };

  return {
    formProps,
    callStatusProps,
    onSubmit,
  };
};
