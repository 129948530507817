import React from 'react';
import { ModalTheme } from '../../../../components/modal';
import { ButtonTheme } from '../../../../components/buttons';
import { color } from '../../../../resources';
import Row from '../../../../components/row';
import { Divider } from 'antd';
import { column } from './constants/column';
import TableCustom from '../../components/table-custom';
import Information from '../../components/information';

function QuotationModal(props) {
  const { companyDetails, quotations, ...modalProps } = props;

  return (
    <ModalTheme
      width={1000}
      title="ใบเสนอราคาทั้งหมด"
      {...modalProps}
      footer={<Footer onClose={props.onToggle} />}
    >
      <Information {...companyDetails} />
      <Divider />
      <TableCustom
        scroll={{ x: 'max-content' }}
        columns={column()}
        dataSource={quotations || []}
        hasPagination={false}
        isShowNo
      />
    </ModalTheme>
  );
}

function Footer({ onClose }) {
  return (
    <Row justifyContent="flex-end" alignItems="center">
      <ButtonTheme
        useFor="CUSTOM"
        title="ปิด"
        type="primary"
        color={color.blue80}
        onClick={onClose}
      />
    </Row>
  );
}

export default QuotationModal;
