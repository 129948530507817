import React from 'react';
import PropTypes from 'prop-types';
import Text from '../text-new';
import { Dropdown as DropdownAntd } from 'antd';

function Dropdown({ options, placement, children, ...rest }) {
  const menuItems = options.map((option, index) => ({
    key: index + 1,
    ...option.itemProps,
    label: (
      <Text variant="span" color={option?.color}>
        {option?.label}
      </Text>
    ),
  }));

  return (
    <DropdownAntd placement={placement} menu={{ items: menuItems }} trigger={['click']} {...rest}>
      {children && <a>{children}</a>}
    </DropdownAntd>
  );
}

Dropdown.propTypes = {
  options: PropTypes.array,
  placement: PropTypes.string,
  children: PropTypes.element,
};

Dropdown.defaultProps = {
  options: [],
  placement: 'bottom',
  children: null,
};

export default Dropdown;
