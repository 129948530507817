import React from 'react';
import Text from '../../../../components/text-new';
import * as companyUtils from '../../../../utils/company';
import { Col, Row } from 'antd';

function Information(props) {
  return (
    <>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={8}>
          <Text variant="span">ชื่อบริษัท : {props?.company?.name || '-'}</Text>
        </Col>
        <Col span={8}>
          <Text variant="span">รหัสบริษัท : {props?.company?.caNumber || '-'}</Text>
        </Col>
        <Col span={8}>
          <Text variant="span">
            สถานะบริษัท :{' '}
            <Text variant="span" color={companyUtils.getBadgeColor(props?.company?.status)}>
              {companyUtils.renderVerifyBadgeName(props?.company?.status)}
            </Text>
          </Text>
        </Col>
      </Row>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={8}>
          <Text variant="span">เบอร์โทร : {props?.company?.telNo || '-'}</Text>
        </Col>
        <Col span={8}>
          <Text variant="span">อีเมลบริษัท : {props?.emailCompany?.companyEmail || '-'}</Text>
        </Col>
        <Col span={8}>
          <Text variant="span">อีเมลผู้ใช้งาน : {props?.emailCompany?.adminEmail || '-'}</Text>
        </Col>
      </Row>
    </>
  );
}

export default Information;
