const CALL_STATUS = [
  { label: 'รับสาย', id: 'CALL-01', color: 'green' },
  { label: 'ไม่รับสาย', id: 'CALL-02', color: 'red' },
  { label: 'ไม่ว่าง', id: 'CALL-03', color: 'grey' },
  { label: 'เบอร์ไม่ถูกต้อง', id: 'CALL-04', color: 'orange' },
];

export const getColor = (id) => CALL_STATUS.find((item) => item.id === id)?.color || null;

export const getLabel = (id) => CALL_STATUS.find((item) => item.id === id)?.label || '-';
