import dj from 'dayjs';
import 'dayjs/locale/th';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dj.extend(duration);
dj.extend(utc);
dj.extend(timezone);
dj.extend(customParseFormat);
dj.extend(advancedFormat);
dj.extend(weekday);
dj.extend(localeData);
dj.extend(weekOfYear);
dj.extend(weekYear);

dj.tz.setDefault('Asia/Bangkok');

export const dayjs = dj;
