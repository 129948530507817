export const column = () => {
  return [
    {
      title: 'โน้ต',
      dataIndex: 'note',
      key: 'note',
      width: '300px',
    },
    {
      title: 'วันที่บันทึก',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: 'ผู้บันทึก',
      dataIndex: 'createBy',
      key: 'createBy',
    },
  ];
};
