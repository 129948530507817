import React from 'react';
import { column } from './constants/column';
import { color, Icon } from '../../../../resources';
import Row from '../../../../components/row';
import TableStyledCustom from '../../components/table-custom';
import { ModalTheme } from '../../../../components/modal';
import { ButtonTheme } from '../../../../components/buttons';
import { openAlert } from '../../../../components/alert/useAlert';
import { useModalContext } from '../../contexts/modal-context';
import { useContactCompanyQuery, useDeleteContact } from '../../hooks/use-contact-query';
import { useCheckOnCall, useDestoryTrxCall } from '../../hooks/use-call-query';
import Information from '../../components/information';

function ContactModal(props) {
  const { companyDetails, ...modalProps } = props;
  const companyId = companyDetails?._id;

  const { openModal, goBack } = useModalContext();

  const { data, isLoading } = useContactCompanyQuery(companyId);
  const { mutate: onDelete } = useDeleteContact();
  const { mutateAsync: onCheckOnCall } = useCheckOnCall();
  const { mutateAsync: onDestroyTrxCall } = useDestoryTrxCall();

  const onClose = () => {
    props.onToggle({ defaultValues: {} });
  };

  const onDeleteContact = (contactId, name) => {
    openAlert({
      model: 'confirm',
      title: `คุณต้องการลบผู้ติดต่อ ${name} ใช่หรือไม่?`,
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk: () => {
        onDelete(contactId);
      },
    });
  };

  const onToggleEdit = (contactDetails) => {
    openModal('EDIT_CONTACT_MODAL', {
      contactDetails: contactDetails,
      onToggle: goBack,
    });
  };

  const onToggleAdd = () => {
    openModal('ADD_CONTACT_MODAL', {
      companyId: companyId,
      onToggle: goBack,
    });
  };

  const onToggleHistoryCall = (contactId) => {
    openModal('HISTORY_CALL_MODAL', {
      companyId: companyId,
      contactId: contactId,
      onToggle: goBack,
    });
  };

  const openCallModal = (otherProps) => {
    openModal('CALL_MODAL', otherProps);
  };

  const onToggleCall = async (contactDetails) => {
    const response = await onCheckOnCall(contactDetails?._id);

    const callModalProps = {
      contactDetails: contactDetails,
      companyDetails: companyDetails,
      companyId: companyId,
      onToggle: goBack,
      lastNote: response?.result?.lastNote || 'ไม่มีโน้ตล่าสุด',
    };

    const isCurrentCall =
      response?.result?.data?.companyId === companyId &&
      response?.result?.data?.customerId === contactDetails?._id;

    if (response?.success && response?.message === 'calling' && !isCurrentCall) {
      openAlert({
        model: 'confirm',
        title: `คุณกำลังอยู่ในสายโทรศัพท์`,
        okText: 'ดำเนินการต่อและตัดสายก่อนหน้า',
        cancelText: 'ปิด',
        onOk: async () => {
          const res = await onDestroyTrxCall();

          if (res?.success) {
            openCallModal({
              ...callModalProps,
            });
          }
        },
      });
    } else {
      openCallModal({
        onCallDetails: response?.result?.data,
        ...callModalProps,
      });
    }
  };

  return (
    <ModalTheme
      width={1000}
      title="ผู้ติดต่อ"
      {...modalProps}
      footer={
        <Footer
          onActions={{
            onToggleAdd,
            onClose,
          }}
        />
      }
    >
      <Information {...companyDetails} />
      <Row isFluid justifyContent="flex-end" padding="8px 0">
        <ButtonTheme
          useFor="CUSTOM"
          type="primary"
          color={color.green80}
          icon={<Icon.plus />}
          title="เพิ่มผู้ติดต่อ"
          onClick={onToggleAdd}
        />
      </Row>
      <TableStyledCustom
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        columns={column({
          onToggleAdd,
          onToggleEdit,
          onToggleCall,
          onToggleHistoryCall,
          onDeleteContact,
        })}
        dataSource={data}
        hasPagination={false}
        isShowNo
      />
    </ModalTheme>
  );
}

function Footer({ onActions }) {
  return (
    <Row justifyContent="flex-end" alignItems="center">
      <ButtonTheme
        useFor="CUSTOM"
        title="ปิด"
        type="primary"
        color={color.blue80}
        onClick={onActions.onClose}
      />
    </Row>
  );
}

export default ContactModal;
