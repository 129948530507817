import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'โปรดระบุให้ถูกต้อง',
    required: 'โปรดระบุ',
    oneOf: 'ต้องเป็นหนึ่งในชุดข้อมูลนี้: ${values}',
    notOneOf: 'ต้องไม่เป็นหนึ่งในชุดข้อมูลนี้: ${values}',
    notType: ({ type }) => (type === 'number' ? 'โปรดระบุ' : 'โปรดระบุให้ถูกต้อง'),
  },
  string: {
    length: 'ต้องระบุจำนวน ${length} ตัวอักษร',
    min: 'ระบุจำนวนอย่างน้อย ${min} ตัวอักษร',
    max: 'ระบุจำนวนมากสุด ${max} ตัวอักษร',
    matches: 'รูปแบบข้อมูลไม่ถูกต้อง',
    email: 'อีเมลไม่ถูกต้อง',
    url: 'รูปแบบ URL ไม่ถูกต้อง',
    trim: 'ต้องเป็นค่าที่ตัดช่องว่างออกแล้ว',
    lowercase: 'ต้องเป็นตัวพิมพ์เล็กทั้งหมด',
    uppercase: 'ต้องเป็นตัวพิมพ์ใหญ่ทั้งหมด',
  },
  number: {
    min: 'ต้องระบุจำนวนมากกว่าหรือเท่ากับ ${min}',
    max: 'ต้องระบุจำนวนน้อยกว่าหรือเท่ากับ ${max}',
    lessThan: 'ต้องระบุจำนวนน้อยกว่า ${less}',
    moreThan: 'ต้องระบุจำนวนมากกว่า ${more}',
    positive: 'ต้องระบุเป็นจำนวนบวก',
    negative: 'ต้องระบุเป็นจำนวนลบ',
    integer: 'ต้องระบุเป็นจำนวนเต็ม',
  },
  date: {
    min: 'ต้องเป็นวันหลังจาก ${min}', //ต้องระบุมากกว่า min วัน นับจากวันปัจจุบัน ป่าวนะ
    max: 'ต้องเป็นวันก่อนหน้า ${max}',
  },
  array: {
    min: 'ต้องมีอย่างน้อย ${min} items',
    max: 'ต้องมีน้อยกว่าหรือเท่ากับ ${max} items',
    length: 'ต้องมีจำนวน ${max} items',
  },
});

export const Yup = yup;
