import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Image = styled.img`
  max-height: 80%;
  width: 100%;
`;
