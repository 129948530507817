import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonCircle = styled(Button).attrs({
  shape: 'circle',
})`
  & > svg {
    vertical-align: middle;
  }
`;

export const ButtonStyle = styled(Button)`
  align-items: center;
  margin: 5px;

  & > svg {
    vertical-align: middle;
    margin-right: 2px;
  }
`;

export const ButtonIconStyle = styled(Button)`
  color: ${(props) => props.color};
  border-color: ${(props) => (props.bgcolor ? props.bgcolor : props.color)};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : 'transparent')};

  :hover,
  :active {
    background-color: ${(props) => (props.bgcolor ? props.bgcolor : 'transparent')} !important;
    border-color: ${(props) => props.color} !important;
    color: ${(props) => props.color} !important;
    opacity: 0.5;
  }
  :focus {
    background-color: ${(props) => (props.bgcolor ? props.bgcolor : 'transparent')} !important;
    border-color: ${(props) => props.color} !important;
    color: ${(props) => props.color} !important;
  }
`;
