import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../forms/yup';
import { useForm } from 'react-hook-form';
import { openAlert } from '../../../../../../../components/alert/useAlert';
import { useEditCallDetails } from '../../../../../hooks/use-table-query';

export const useFormState = ({ companyDetails, onCloseModal, options }) => {
  const defaultValues = {
    keySuccess: companyDetails?.keySuccess,
    stageManual: companyDetails?.stageManual,
    purchaseOccasion: companyDetails?.purchaseOccasion,
    knowUs: companyDetails?.knowUs,
    totalCandidate: companyDetails?.totalCandidate,
    positionReceived: companyDetails?.positionReceived,
    avgJobPost: companyDetails?.avgJobPost,
    avgSearch: companyDetails?.avgSearch,
    favPosition: companyDetails?.favPosition,
    findPosition: companyDetails?.findPosition || [],
    competitors: companyDetails?.competitors,
    budgetPeriod: companyDetails?.budgetPeriod,
    budget: companyDetails?.budget,
    quotations: companyDetails?.quotations.map((items) => items?.quotationNo) || [],
    additionalNote: companyDetails?.additionalNote,
  };

  const { mutateAsync: onEdit } = useEditCallDetails();

  const {
    control,
    formState: { isDirty },
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const formProps = {
    control: control,
    setValue: setValue,
    getValues: getValues,
    options: options || {},
  };

  const onCancel = ({ onAfterFinish }) => {
    if (isDirty) {
      openAlert({
        model: 'confirm',
        title: 'คุณต้องการออกจากการแก้ไข ?',
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          onAfterFinish();
        },
        onCancel: () => {},
      });
      return;
    }
    onAfterFinish();
  };

  const onSubmit = () => {
    handleSubmit(
      async (formValues) => {
        const companyId = companyDetails?._id;
        const reqBody = {
          ...formValues,
        };
        try {
          await onEdit({ companyId, reqBody });
          onCloseModal();
        } catch (err) {}
      },
      () => {},
    )();
  };

  return {
    formProps,
    onSubmit,
    onCancel,
  };
};
