import { useDispatch } from 'react-redux';
import { checkTokenLogin } from '../../../redux/authen/action';

export const useLogin = () => {
  const dispatch = useDispatch();

  const dispatchLogin = (auth) => {
    dispatch(login(auth));
  };

  const dispatchCheckTokenLogin = (payload) => {
    dispatch(checkTokenLogin(payload));
  };

  return {
    dispatchLogin,
    dispatchCheckTokenLogin,
  };
};
