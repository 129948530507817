import { Modal } from 'antd';
import styled from 'styled-components';
import { color } from '../../resources';

export const ModalAntd = styled(Modal)`
  top: 0;
  max-height: 100vh;

  .ant-modal-content {
    max-width: 100vw;
    margin-top: ${(props) => props.modalPreset.MARGIN_TOP_BOTTOM}px;
    margin-bottom: ${(props) => props.modalPreset.MARGIN_TOP_BOTTOM}px;
  }

  .ant-modal-header {
    height: ${(props) => props.modalPreset.HEADER_HEIGHT}px;
    border-bottom: 1px solid ${color.grey200} !important;
  }

  .ant-modal-body {
    padding: unset;
    height: 100vh;
    min-height: 400px;
    max-height: ${(props) => props.modalPreset.bodyHeight && `${props.modalPreset.bodyHeight}px`};
  }

  .ant-modal-footer {
    min-height: ${(props) => props.modalPreset.FOOTER_HEIGHT}px;
  }
`;

export const ModalContentContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 16px;
`;
