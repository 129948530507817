import React from 'react';
import { Space } from 'antd';
import { ButtonTheme } from '../../../../components/buttons';
import { ModalTheme } from '../../../../components/modal';
import { color } from '../../../../resources';
import EditForm from '../../components/edit-form';
import { useFormState } from '../../components/edit-form/hooks/useFormState';
import { useEditContact } from '../../hooks/use-contact-query';

function EditContactModal(props) {
  const { contactDetails, ...modalProps } = props;

  const { mutate: editContact, isLoading: isSubmitLoading } = useEditContact({
    onCallback: props.onToggle,
  });

  const { formProps, onCancel, onSubmit } = useFormState({
    contactDetails,
    onAction: (reqBody) => {
      editContact({
        contactId: contactDetails?._id,
        reqBody: reqBody,
      });
    },
  });

  const onCloseModal = () => {
    onCancel({
      onAfterFinish: props.onToggle,
    });
  };

  return (
    <ModalTheme
      {...modalProps}
      width={1000}
      title="แก้ไขข้อมูล"
      onToggle={onCloseModal}
      footer={<Footer onCancel={onCloseModal} onSubmit={onSubmit} />}
    >
      <EditForm {...formProps} />
    </ModalTheme>
  );
}

function Footer({ onCancel, onSubmit }) {
  return (
    <Space>
      <ButtonTheme
        useFor="CUSTOM"
        title="ยกเลิก"
        type="primary"
        color={color.grey400}
        onClick={onCancel}
      />
      <ButtonTheme
        useFor="CUSTOM"
        title="บันทึก"
        type="primary"
        color={color.blue80}
        onClick={onSubmit}
      />
    </Space>
  );
}

export default EditContactModal;
