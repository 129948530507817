import React from 'react';
import { ButtonTheme } from '../../../../components/buttons';
import Row from '../../../../components/row';
import { color, Icon } from '../../../../resources';

function TableActions({ onClick }) {
  return (
    <Row justifyContent="center" alignItems="center" gap="8px">
      <ButtonTheme
        type="primary"
        useFor="CUSTOM"
        color={color.edit}
        title="จัดการสิทธิ์"
        icon={<Icon.editCall />}
        onClick={onClick}
      />
    </Row>
  );
}

export default TableActions;
