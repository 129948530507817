import SUCCESS_MESSAGES from './success-msg.json';
import ERROR_MESSAGES from './error-msg.json';

export const getSuccessMsg = (msg) => {
  return SUCCESS_MESSAGES[msg] || SUCCESS_MESSAGES.default;
};

export const getErrorMsg = (msg) => {
  return ERROR_MESSAGES[msg] || ERROR_MESSAGES.default;
};
