import React from 'react';
import TableActions from '../../../components/table-actions';
import { isEmpty } from 'lodash';
import { Space } from 'antd';

export const COLUMN = ({ pageStep, onToggleRow }) => {
  return [
    {
      title: 'No.',
      width: 70,
      align: 'center',
      render: (_, records, index) => <span>{index + pageStep}</span>,
    },
    {
      title: 'รหัสเซล',
      dataIndex: 'code',
      key: 'code',
      width: 120,
      align: 'center',
    },
    {
      title: 'แอดมิน',
      dataIndex: 'adminId',
      key: 'adminId',
      align: 'center',
      render: (values) => (
        <Space style={{ width: '100%' }}>
          <span>{!isEmpty(values) ? values.map((v) => v.name).join(', ') : '-'}</span>
        </Space>
      ),
    },
    {
      title: 'จำนวนผู้ดูแล',
      align: 'center',
      width: 120,
      render: (_, records) => <span>{records?.adminId?.length || 0}</span>,
    },
    {
      title: '',
      key: 'actions',
      width: 150,
      align: 'center',
      render: (_, records) => <TableActions onClick={() => onToggleRow(records)} />,
    },
  ];
};
